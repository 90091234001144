<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?'编辑遥监设备':'新增遥监设备'"
    width="500px"
    :modal="false"
    top="3vh"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      :label-width="$l('device.labelWidth','80px')">
      <el-form-item label="设备名称/编号" prop="name">
        <el-input v-model="model.name" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>



      <el-form-item label="物联网协议来源" prop="iotProtocolSource">
        <vm-dict-select v-model="model.iotProtocolSource" type="iotProtocolSource"></vm-dict-select>
      </el-form-item>
      <el-form-item label="设备型号" prop="model">
        <vm-dict-select v-model="model.model" type="iotDeviceModel"></vm-dict-select>
      </el-form-item>
      <el-form-item label="设备状态" prop="status">
        <vm-dict-select v-model="model.status" :disabled="!$auth('遥监设备状态')" type="iotDeviceStatus"></vm-dict-select>
      </el-form-item>
      <el-form-item label="所属公司" prop="companyId">
        <el-select v-model="model.companyId" :placeholder="$l('common.enter','请输入')" :disabled="parentId">
          <el-option
            v-for="item in data"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="协议版本" prop="iotProtocolVersion">
        <el-input v-model="model.iotProtocolVersion"></el-input>
      </el-form-item>
      <el-form-item label="支持主板" prop="iotSupportBoardType">
        <vm-dict-select v-model="model.iotSupportBoardType" type="iotSupportBoardType"></vm-dict-select>
      </el-form-item>
      <el-form-item label="电信运营商" prop="isp">
        <vm-dict-select v-model="model.isp" type="isp"></vm-dict-select>
      </el-form-item>
      <el-form-item label="SIM卡号" prop="simCode">
        <el-input v-model="model.simCode" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
      <el-form-item label="ICCID" prop="iccidCode">
        <el-input v-model="model.iccidCode" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel","取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save","保存")}}</el-button>
    </span>
    <real-estate-select ref="realEstateSelect" @select="realEstateSelect"></real-estate-select>
    <building-select ref="buildingSelect" @select="buildingSelect"></building-select>
  </el-dialog>
</template>
<script>
  import RealEstateSelect from "@/views/elevatorUsingRealEstate/RealEstateSelect";
  import BuildingSelect from "@/views/elevatorUsingBuilding/BuildingSelect";
  import loginUtil from "@/util/loginUtil";

  export default {
    components: {RealEstateSelect,BuildingSelect},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        model: {
          id: 0,
          no: "",
          source: "",
          isp: null,
          iccid: "",
          companyId: loginUtil.getCompanyCode(),
        },
        data: [],
      };
    },
    methods: {
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        this.getData();
        this.getTreeList();
      },
      getData() {
        if (this.model.id) {
          this.contentLoading = true;
          this.$http.get(`/elevator-iot/device/${this.model.id}`).then(data => {
            this.contentLoading = false;
            this.model = data;
          });
        }
      },
      getTreeList() {
        let http =  this.$http.get("/organization/company/page",{companyId:""},{pageSize:100});
        http.then(data => {
          this.data = data.records;
        }).catch(() => {
          this.status = "error";
        });
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            if (!this.model.isp) {
              this.model.isp=null;
            }
            this.$http
              .save("elevator-iot/device", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
      realEstateSelect(row) {
        this.$set(this.model,"realEstateId", row.id);
        this.$set(this.model,"realEstateName", row.name);

      },
      buildingSelect(row) {
        this.$set(this.model,"buildingId", row.id);
        this.$set(this.model,"buildingName", row.name);

      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
